@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');

:root {

  --font-family-body: 'Inter', sans-serif;

  --font-body: 16px var(--font-family-body);

  --color-aframe-primary: #E03F96;
  --color-aframe-text: #000000;
  --color-aframe-success: #71C040;
  --color-aframe-warning: #F09F1F;
  --color-aframe-cold: #0066FF;
  --color-aframe-warm: #E03F3F;
  --color-white: #FFFFFF;
  --color-aframe-border: #D9D9D9;

}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  min-width: 320px;
  margin: 0;
  background-color: var(--color-white);
  font: var(--font-body);
  font-stretch: normal;
  font-weight: normal;
  color: var(--color-aframe-text);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.bold {
  font-weight: bold;
}

.content {
  padding: 0 20px;
}

.caption {
  font-size: 12px;
}

.block {
  display: block;
  width: 100%;
}

h1 {
  margin-top: 80px;
  margin-bottom: 40px;
  font-size: 32px;
  font-weight: normal;
  color: var(--color-aframe-primary);
}