.home {
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 48px);

  .content {
    flex: 1 1;

    .status {
      margin-top: 16px;
      margin-bottom: 16px;
      text-align: center;
      font-weight: 600;
      .success {
        color: var(--color-aframe-success);
      }
      .warning {
        color: var(--color-aframe-warning);
      }
    }
    .title {
      font-size: 72px;
      font-weight: 500;
      color: var(--color-aframe-primary);
      text-align: center;
    }
    .caption {
      text-align: center;
    }

    .rooms {
      margin-top: 40px;
      display: flex;
      flex-direction: row;
      gap: 20px;
      .room {
        flex: 1 1;
        display: flex;
        flex-direction: column;
        gap: 16px;
        padding-top: 16px;
        align-items: center;
        border-top: 1px solid var(--color-aframe-border);
        .icon {
          display: block;
          width: 32px;
          height: 32px;
        }
      }
    }

  }

  .controls {
    display: flex;
    flex-direction: row;
    gap: 8px;
    justify-content: center;
    margin-top: 32px;
    padding-top: 16px;
    border-top: 1px solid var(--color-aframe-border);
    .current {
      padding: 0 12px;
      line-height: 40px;
      border-radius: 20px;
      background-color: #f0f0f0;
      //border: 1px solid var(--color-aframe-border);
    }
    .button {
      padding: 0 12px;
    }
  }

}